<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'

export default {
  name: 'publicParams',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/param/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '参数名称',
          type: 'input',
          key: 'publicName'
        },
        {
          label: '',
          type: 'text',
          key: ''
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'publicId',
          title: 'ID',
          type: 'lt-90',
          isId: true,
          sorter: (a, b) => a.publicId - b.publicId
        },
        {
          dataIndex: 'publicName',
          title: '参数名称',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.publicName.length - b.publicName.length
        },
        {
          dataIndex: 'publicKey',
          title: '键',
          type: 'lt-100',
          sorter: (a, b) => a.publicKey.length - b.publicKey.length
        },
        {
          dataIndex: 'publicValue',
          title: '系统值',
          type: 'lt-100',
          sorter: (a, b) => a.publicValue.length - b.publicValue.length
        },

        {
          dataIndex: 'createTime',
          isId: true,
          title: '创建时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix()
        },
        {
          dataIndex: 'updateTime',
          isId: true,
          title: '修改时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.updateTime).unix() - moment(b.updateTime).unix()
        },
        {
          dataIndex: 'sys',
          title: '系统内置',
          width: 80,
          type: 'badge',
          onExport: records => {
            return ['否', '是'][records]
          },
          filters: [
            {
              text: '是',
              value: '1'
            },
            {
              text: '否',
              value: '0'
            }
          ],
          onFilter: (value, record) => record.internal == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '否' : '是',
              color: data == 0 ? 'red' : 'green'
            }
          }
        },
        {
          dataIndex: 'internal',
          title: '内部参数',
          width: 80,
          type: 'badge',
          onExport: records => {
            return ['是', '否'][records]
          },
          filters: [
            {
              text: '是',
              value: '0'
            },
            {
              text: '否',
              value: '1'
            }
          ],
          onFilter: (value, record) => record.internal == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '是' : '否',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'status',
          title: '状态',
          width: '60px',
          type: 'badge',
          onExport: records => {
            return ['正常', '失效'][records]
          },
          filters: [
            {
              text: '正常',
              value: '0'
            },
            {
              text: '失效',
              value: '1'
            }
          ],
          onFilter: (value, record) => record.status == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '正常' : '失效',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              //   {
              //     name: records.status == 0 ? '失效' : '正常',
              //     onClick: () => {
              //       api.command.edit.call(this, {
              //         url: `/app/${
              //           records.status == 0 ? 'disable' : 'enable'
              //         }?appId=${records.appId}`
              //       })
              //     }
              //   },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/system/publicParamsDetail?id=${records.publicId}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/param/${records.publicId}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/system/publicParamsDetail')
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //
        //       }
        //     }
        //   ]
        // }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
